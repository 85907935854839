@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".ShopCartDialogProducts";

#{$selector} {
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $space-lg;
    padding-bottom: $space-lg;
    border-bottom: 1px solid $border-lightgray;

    &:first-of-type {
      padding-top: $space-lg;
      border-top: 1px solid $border-lightgray;
    }
  }

  &__counter {
    display: flex;
    flex-direction: column;

    &__button {
      width: 40px;
      height: 40px;
      background: none;
      border: 1px solid $border-primary;
      border-radius: 200px;
      cursor: pointer;

      &--gray {
        border: 1px solid $border-lightgray;
      }
    }

    &__icon {
      color: $icon-primary;

      &--gray {
        color: $text-lightgray;
      }
    }

    &__sum {
      @include text("base");
      padding: $space-md 0;
      text-align: center;
    }
  }

  &__content {
    display: flex;
    align-items: center;
  }

  &__col {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &__image {
    position: relative !important;
    max-width: 100px !important;
    margin-right: $space-md;
  }

  &__title {
    @include text("base");
    max-width: 150px;
    overflow: hidden;
    color: #000000;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__sum {
    @include text("meta");
    padding: $space-sm 0;
  }

  &__total {
    @include text("base");
    color: $text-primary;
  }

  &__remove {
    padding-left: $space-lg;
    background: none;
    border: none;
    cursor: pointer;

    &__icon {
      color: $icon-base;
    }
  }

  &__noData {
    @include text("meta");
    max-width: 300px;
    padding-bottom: $space-xl;
  }
}
