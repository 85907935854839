@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".Dropdown";

#{$selector} {
  position: relative;

  &__content {
    position: absolute;
    right: 0;
    z-index: $layer-dropdown;
    background: $background-base;
    border-radius: $ui-default-border-radius;
    box-shadow: $ui-elevation-dropdown;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;

    &--is-open {
      visibility: visible;
      opacity: 1;
    }
  }

  &__button {
    background: none;
    border: none;
    cursor: pointer;
  }
}
