@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".FileUpload";

#{$selector} {
  position: relative;
  width: 100%;
  text-align: center;

  &__title {
    @include text("base");
    display: block;
    padding-bottom: $space-lg;
    text-align: left;
  }

  &__input {
    display: none;
  }

  &__label {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    padding: $space-lg;
    background-color: $background-lightgray;
    border-color: $border-primary;
    border-style: dashed;
    border-width: 2px;
    border-radius: 1rem;

    &--is-disabled {
      background-color: $background-base;
      border-color: $border-primary;
      opacity: 0.5;
    }
  }

  &__description {
    @include text("base");
  }

  &__button {
    @include text("component/fileUpload/button");

    padding-top: $space-lg;
    background-color: transparent;
    border: none;
    cursor: pointer;

    &--is-disabled {
      pointer-events: none;
    }

    @include when-hovered {
      text-decoration: underline;
    }
  }

  &__fileLength {
    @include text("meta");
    position: absolute;
    right: 20px;
    bottom: 20px;
  }

  &__drag {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
  }

  &__file__wrapper {
    padding-top: $space-lg;
  }

  &__file {
    @include text("meta");
    display: flex;
    justify-content: space-between;
    padding-bottom: $space-md;
  }

  &__fileRestrictions {
    display: flex;
    justify-content: space-between;
  }
}
