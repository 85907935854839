@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".BookingDetailBanner";

#{$selector} {
  &__title__wrapper {
    display: flex;
  }

  &__title {
    @include text("component/documentBox/headline");
    display: block;
    margin-bottom: $space-lg;
  }

  &__title__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin-right: $space-md;
    background: $ui-gradiant-icon;
    border-radius: 50px;
  }

  &__icon {
    color: $icon-white;
  }

  &__content {
    display: block;
    margin-left: $space-md;
    padding: 0 $space-xs 0 $space-2xl;
  }

  &__button {
    margin-top: $space-lg !important;
  }
}
