@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".BookingDetail";

#{$selector} {
  &__header {
    display: flex;
    justify-content: space-between;

    &__wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: $space-lg;
    }

    &__headline {
      margin-bottom: 0 !important;
    }

    &__status {
      @include text("service/detail/status");
      height: 30px;
      margin-top: $space-xs;
      margin-right: $space-lg;
      margin-left: $space-lg;
      padding: $space-xs $space-md;
      background-color: $background-gray;
      border-radius: $ui-default-border-radius;

      &--Draft {
        background-color: $background-darkgray;
      }

      &--Placed {
        background-color: $background-success;
      }

      &--Closed {
        background-color: $background-error;
      }
    }
  }

  &__info {
    margin-bottom: $space-2xl;
    padding-bottom: $space-lg;
  }
}
