@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".ShopCartDialog";

#{$selector} {
  width: 400px;
  padding: $space-lg;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $space-xl 0;
  }

  &__counter {
    display: flex;
    align-items: center;

    &__title {
      @include text("base");
      padding-left: $space-sm;
    }
  }

  &__close {
    background: none;
    border: none;
    cursor: pointer;
  }

  &__footer {
    display: flex;
    flex-direction: column;
  }

  &__button {
    margin-bottom: $space-lg !important;
  }
}
