@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".InfoBox";

#{$selector} {
  margin-bottom: $space-2xl;

  &__title {
    @include text("component/documentBox/headline");
  }

  &__items {
    margin-top: $space-md;
    margin-left: $space-xs !important;
  }

  &__item {
    display: flex;
    flex-direction: row;
    margin-bottom: $space-sm;

    &__icon {
      flex: 0 0 15px;
      fill: $icon-primary;
    }

    &__link {
      @include text("component/documentBox/item");
      margin-left: $space-sm;
    }
  }
}
