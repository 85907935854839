@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".Header";

#{$selector} {
  position: sticky;
  top: 0;
  z-index: $layer-header;
  width: 100%;
  min-height: 120px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &__content {
    display: block;
    gap: $space-2xl;
    width: 100%;
    padding: $space-lg;

    @include screenLargerThan($screen-lg) {
      display: flex;
    }
  }

  &__col {
    position: relative;
    z-index: $layer-header;
    display: flex;
    align-items: center;
    width: 100%;

    &--left {
      @include screenSmallerThan($screen-lg) {
        justify-content: space-between;
      }
    }

    &--right {
      justify-content: space-between;
      margin-top: 20px;

      @include screenLargerThan($screen-lg) {
        justify-content: flex-end;
        margin-top: 0;
      }
    }

    &__content {
      display: flex;
      align-items: center;
    }
  }

  &__avatar__language__wrapper {
    display: flex;
  }

  &__avatar {
    display: flex;
    flex-direction: row-reverse;
    gap: $space-sm;
    align-items: center;
    justify-content: center;
    margin-left: $space-lg;

    &__image {
      width: 25%;
    }

    &--is-unauthorized {
      cursor: pointer;
    }

    &__link {
      @include when-hovered {
        text-decoration: none;
      }
    }

    &__content,
    &__dropdown {
      display: none;

      @include screenLargerThan($screen-lg) {
        @include text("base");
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        color: $ui-header-text;
      }
    }

    &__dropdown {
      display: block;
      width: 320px;
      padding: $space-md;
      color: $text-base;
    }

    &__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &__company {
        @include text("meta");
        display: inline-block;
        word-break: break-word;
      }
    }

    &__header__signout {
      @include text("meta");
      display: inline-block;
      color: $text-primary;
      cursor: pointer;

      @include when-hovered {
        text-decoration: underline;
      }
    }

    &__user {
      @include text("meta");
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: $space-lg;
      text-align: right;

      &__info {
        width: 75%;
        padding-left: $space-md;
      }

      &__name {
        @include text("header/dropdown/name");
        text-align: right;
      }

      &__email {
        display: block;
        margin-top: $space-xs;
        overflow: hidden;
        white-space: nowrap;
        text-align: right;
        text-overflow: ellipsis;
      }
    }

    &__user__link {
      text-decoration: underline;

      @include when-hovered {
        text-decoration: none;
      }
    }

    @include screenLargerThan($screen-lg) {
      position: relative;
      right: auto;
    }
  }

  &__cart {
    position: relative;
    cursor: pointer;

    &__badge {
      @include text("meta");
      position: absolute;
      top: -7px;
      right: -7px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      color: $text-white;
      background-color: $background-primary;
      border-radius: 100px;
    }
  }

  &__toggle {
    &__select {
      padding-top: $space-sm !important;
      background: none !important;
    }

    &__selectedItem {
      color: $ui-header-text !important;
      background-color: transparent !important;
      transition: none !important;

      &::before,
      &::after {
        content: none !important;
      }

      @include when-hovered {
        background-color: transparent;
      }
    }

    &__icon {
      top: $space-sm !important;
      right: 15px !important;
      fill: $icon-white !important;
    }

    &__item {
      color: $ui-header-text !important;
    }

    @include screenLargerThan($screen-lg) {
      margin-left: $space-lg;
    }
  }

  &__navigation__toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    margin-left: $space-lg;
    padding: $space-xs;
    background-color: $background-base;
    border-radius: 50px;
    cursor: pointer;

    &__icon {
      width: 20px;
      fill: $icon-secondary;
    }

    @include screenLargerThan($screen-lg) {
      position: relative;
      right: auto;
    }
  }

  &__logo {
    &__main {
      max-height: 50px;
      cursor: pointer;
    }

    &__description {
      display: none;

      @include screenLargerThan($screen-lg) {
        @include text("base");
        display: block;
        margin: 0 $space-lg;
        color: $ui-header-text;
      }
    }

    &__secondary {
      max-width: 150px;
      max-height: 50px;
    }
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: $background-secondary;
    opacity: var(--opacity);
    content: "";
  }
}
