@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".ShopOverviewStandSelectionDialog";

#{$selector} {
  &__desc {
    @include text("meta");
    padding-bottom: $space-lg;
  }

  &__helperText {
    @include text("base");
  }
}
